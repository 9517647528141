/* IMPORT */
import channel from './channel.js';
import lang from './lang.js';
import unit from './unit.js';
/* MAIN */
const Utils = {
    channel,
    lang,
    unit
};
/* EXPORT */
export default Utils;
