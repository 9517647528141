/* MAIN */
const Unit = {
    /* API */
    dec2hex: (dec) => {
        const hex = Math.round(dec).toString(16);
        return hex.length > 1 ? hex : `0${hex}`;
    }
};
/* EXPORT */
export default Unit;
